import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './components/App';


ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
    module.hot.accept('./components/Master/index', () => {
      const NextApp = require('./components/Master/index').default
      ReactDOM.render(
        <NextApp />,
        document.getElementById('root')
      )
    })
  }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
