import React from "react";
import UXlogo from "./../../assets/Icons/UX.svg";
import ITlogo from "./../../assets/Icons/IT.svg";
import EMlogo from "./../../assets/Icons/EM.svg";
import TElogo from "./../../assets/Icons/TE.svg";
import MGlogo from "./../../assets/Icons/MG.svg";
import VIlogo from "./../../assets/Icons/VI.svg";
import ELlogo from "./../../assets/Icons/EL.svg";
import MElogo from "./../../assets/Icons/ME.svg";
import txt from "./../../assets/languages";

import ListGroup from 'react-bootstrap/ListGroup'
import ReactTooltip from 'react-tooltip'

import './style.css';

const iconURLs = [
    UXlogo,
    ITlogo,
    EMlogo,
    TElogo,
    MGlogo,
    VIlogo,
    ELlogo,
    MElogo
  ];

const CategoryIcon = ({id, onCheckCategory, boxes, label, lang}) => {
  return (
    <label
      name={"Category " + id}
      type="checkbox"
      value={id}
      onClick={onCheckCategory}
      className={boxes[id] === true ? "selected" : ""}
      data-tip={txt[lang].categoryLabelsTooltip[id]}
    >
      <img alt={label} value={id} className="categoryIcon" src={iconURLs[id]} />
      <span value={id}>{label}</span>
    </label>
  );
}


const CategoryPane = ({ boxes, onCheckCategory, isSubPage, searchMode, lang}) => 
    <ListGroup className={isSubPage? "CategoryPane-subPage":"CategoryPane"}>
        <div className={isSubPage?"categoryList-subPage":"categoryList"}>
        {txt[lang].categoryLabels.map((item, index) => <CategoryIcon
            key={"check"+ index}
            value={index}
            id={index}
            onCheckCategory={onCheckCategory}
            boxes={boxes}
            label={item}
            lang={lang}
            // className={(this.props.categories[this.props.categoryID] != 0) ? "selected" : ""}
        >
        </CategoryIcon>
        )}
        </div>
        <ReactTooltip place="right"/>
    </ListGroup>
  
export default CategoryPane;

export {
    iconURLs
}