import React from "react";

// import Card from 'react-bootstrap/Card'
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import txt from "./../../assets/languages";

import "./style.css";

const List = ({
  list,
  page,
  isLoading,
  onPaginatedSearch,
  isDisplaying,
  match,
  displayPane,
  searchMode,
  searchCount,
  favCount,
  onSetMode,
  totalJobs,
  totalFavorites,
  lang,
}) => (
  <div>
    <div className="favTab">
        <span className={searchMode ? "selected" : ""} onClick={() => onSetMode(true)}>
          {searchCount ? searchCount : ""} {txt[lang].of} {totalJobs} {txt[lang].results}
        </span>
        <span className={!searchMode ? "selected" : ""} onClick={() => onSetMode(false)}>
          {txt[lang].favorites} {favCount<totalFavorites? favCount+" ("+totalFavorites+")":totalFavorites}
        </span>
      </div>

    <div
      className={
        match.isExact && match.path === "/"
          ? "listContainer root"
          : "listContainer"
      }
    >
      <ListGroup className={isLoading ? "hitlist loading" : "hitlist"}>
      {/* {console.log("LIST:::     ::")}
      {console.table(list)} */}
        {list &&
          list.map((item) => (
            <div className="list-item" href={item.url} key={"div-" + item.key}>
              <Link
                onClick={() => {
                  displayPane(item.key);
                  localStorage.setItem(item.id, "1");
                }}
                to={`/id/${item.key}`}
              >
                <ListGroup.Item
                  className={
                    parseInt(match.params.key) === item.key ? "current" : " "
                  }
                >
                  <h5>{item.title}</h5>
                  <div className="d-flex justify-content-between">
                    <span className="w-100">{
                      (item.id.substring(0,4) === "VOLG") ? "Volvo Group" :
                      (item.id.substring(0,4) === "VOLV") ? "Volvo Cars" :
                      (item.id.substring(0,3) === "JR-") ? "EworkGROUP" :
                      (item.id.substring(0,3) === "RFQ") ? "CEVT" : ""
                    }</span>
                    <span className="right w-50">
                      {item.arrived.substring(0, 10)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="w-100">{item.id}</span>
                    {localStorage.getItem(item.id) !== "1" &&
                      <h5 className="new-assignment right w-50">{txt[lang].new}</h5>
                    }
                  </div>
                </ListGroup.Item>
              </Link>
            </div>
          ))}
      </ListGroup>
    </div>
  </div>
);

export default List;
