
const txt = {
  "sv": {
    aboutAssignment: "Om uppdraget",
    addAssignment: "Lägg till uppdrag",
    addNotif: "Lägg till",
    addNotification: "Lägg till notis",
    added: "Inlagd:",
    all: "Alla",
    answerLatest: "Svara senast:",
    assignmentportal: "Uppdragsportalen",
    cancelEdit: "Avbryt edit",
    categories: "Kategorier:",
    categories2: "Kategorier",
    categories_gpt: "Kategorier GPT:",
    categories_ai: "Kategorier AI classifier:",
    categoryLabels: [
        "UI/UX",
        "IT/SW",
        "Embed",
        "Testning",
        "Agile",
        "Visuell",
        "Elektro",
        "Mek"
    ],
    categoryLabelsTooltip: [
        "UX & Service Design",
        "IT & Cloud Services",
        "Connected & Embedded Systems",
        "Test & Quality Engineering",
        "Agile Management",
        "Simulation & Visualization",
        "Green Technology & Electromobility",
        "Mechanical Engineering"
    ],
    change: "Ändra uppgifter",
    chooseNone: "Välj ingen",
    chooseAll: "Välj alla",
    city: "Stad:",
    clearFilters: "Rensa filter",
    closeWindow: "Stäng fönster",
    deleteNotif: "Ta bort",
    descLooksStrange: "Om detta uppdrag ser konstigt ut, ladda ner pdf-versionen med knappen till höger.",
    description: "Beskrivning",
    downloadOriginal: "Ladda hem original",
    east: "Öst",
    edit: "Redigera",
    emailBody: "Hej, här är mina favorit-uppdrag från Uppdragsportalen.",
    emailBodyOne: "Uppdrag: ",
    emailBodyPartial: "Del av uppdragslista pga gräns för karaktärer...",
    emailSubject: "Uppdragsportalen favoritlista",
    emailSubjectOne: "Uppdrag från Uppdragsportalen",
    end: "Slut:",
    favClickToAdd: "Klicka för att lägga till favorit",
    favorite: "Favorit",
    favorites: "Favoriter",
    fetchFailed: "Kunde inte hämta uppdrag...",
    fillNotifName: "Vänligen fyll i notis-namn.",
    latestSendout: "Senaste utskick",
    loading: "Laddar...",
    loggedInAs: "Inloggad som:",
    logOut: "Logga ut",
    matchesCount: "Antal träffar utskickade",
    months: [
        "januari",
        "februari",
        "mars",
        "april",
        "maj",
        "juni",
        "juli",
        "augusti",
        "september",
        "oktober",
        "november",
        "december",
    ],
    new: "Ny!",
    newNotif1: "Ny notis '",
    newNotif2: "' tillagd",
    newsBody: `- Uppdaterat gränssnitt\n
- Lade till Engelsk översättning\n
- Kommer ihåg vilka uppdrag som har blivit öppnade.\n
- Dela favoritlistan via mail samt bättre formatering för delning av enskilda uppdrag.\n
- Sparar vald filtrering mellan sessioner.\n
- Ny knapp för att rensa filter.\n
- Visar mer information under "Om uppdraget".\n`,
    newsHeader: "Nyheter 2023-10-26:",
    no: "Nej",
    noEditing: "Går ej att ändra under \"edit-mode\"",
    notificationCategoriesInfo: "Notifikationen tar endast med jobb i matchande kategorier",
    notifications: "Anpassa notiser",
    notifName: "Notis-Namn",
    notifNameInfo: "Namnet för notis, t.ex. embedded i väst",
    notifPeriodicity: "Hur ofta vill du få notifikations-mail?",
    notifSameName: "Du har redan en notis med detta namn, vänligen välj ett nytt.",
    notifUpd1: "Notis '",
    notifUpd2: "' uppdaterad",
    of: "av",
    onceADay: "En gång om dagen (09:00)",
    onceAMonth: "En gång per månad (Första dagen (mån-fre) i månaden 09:00)",
    onceAWeek: "En gång per vecka (Varje måndag 09:00)",
    openSettings: "Öppna inställningar",
    region: "Region:",
    region2: "Region",
    removeFavorite: "Ta bort favorit",
    removeNotif: "Vill du ta bort notifikationen?",
    removeSeen: "Markera som oläst",
    results: "resultat",
    search: "sök",
    searchAssignment: "Sök uppdrag",
    searchWord: "Sökord",
    searchWordAdded: "Sökordet är redan tillagt, vänligen välj ett annat.",
    searchWordAtLeast2: "Sökordet behöver vara minst två tecken!",
    searchWordInfo: "Notifikationen tar endast med jobb som matchar något av sökorden, lämna blankt för att inkludera alla",
    searchWordPlaceholder: "Skriv in ett sökord och tryck enter",
    selectRegions: "Välj regioner:",
    sendout: "Utskick",
    share: "Dela",
    shareFavoriteList: "Dela favoritlista",
    south: "Syd",
    start: "Start:",
    switchLanguage: "In English",
    tryAgain: "Försök igen",
    twiceADay: "Två gånger om dagen (09:00 och 13:00)",
    west: "Väst",
    welcome: "Välkommen till Uppdragsportalen!",
    updNotif: "Uppdatera Notis",
    yes: "Ja",
    //inDevelopment: "(under utveckling)",
    yourNotif: "Dina notifikationer",
  },
  "en": {
    aboutAssignment: "About the assignment",
    addAssignment: "Add assignment",
    addNotif: "Add",
    addNotification: "Add notification",
    added: "Added:",
    all: "All",
    answerLatest: "Answer latest:",
    assignmentportal: "Assignment Portal",
    cancelEdit: "Cancel edit",
    categories: "Categories:",
    categories2: "Categories",
    categories_gpt: "Categories GPT:",
    categories_ai: "Categories AI classifier:",
    categoryLabels: [
        "UI/UX",
        "IT/SW",
        "Embed",
        "Testing",
        "Agile",
        "Visual",
        "Electro",
        "Mech"
    ],
    categoryLabelsTooltip: [
        "UX & Service Design",
        "IT & Cloud Services",
        "Connected & Embedded Systems",
        "Test & Quality Engineering",
        "Agile Management",
        "Simulation & Visualization",
        "Green Technology & Electromobility",
        "Mechanical Engineering"
    ],
    change: "Change information",
    chooseNone: "Choose none",
    chooseAll: "Choose all",
    city: "City:",
    clearFilters: "Clear filters",
    closeWindow: "Close window",
    deleteNotif: "Delete",
    descLooksStrange: "If this assignment looks strange, download the pdf-verion with the button to the right.",
    description: "Description",
    downloadOriginal: "Download original",
    east: "East",
    edit: "Edit",
    emailBody: "Hi, here are my favorite positions from Uppdragsportalen.",
    emailBodyOne: "Assignment: ",
    emailBodyPartial: "Partial assignment list due to character limit...",
    emailSubject: "Assignmentportal favorite list",
    emailSubjectOne: "Assignment from the Assignment portal",
    end: "End:",
    favClickToAdd: "Click to add to favorites",
    favorite: "Favorite",
    favorites: "Favorites",
    fetchFailed: "Could not fetch assignments...",
    fillNotifName: "Please fill in a notification name.",
    latestSendout: "Latest sendout",
    loading: "Loading...",
    loggedInAs: "Logged in as:",
    logOut: "Log out",
    matchesCount: "Number of matches sent out",
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    new: "New!",
    newNotif1: "New notification '",
    newNotif2: "' added",
    newsBody: `- Updated interface.\n
- Added English translation.\n
- Remembers which assignments have been opened.\n
- Share the favorites list via email as well as better formatting for sharing single assignments.\n
- Save choosen filtering across sessions.\n
- New button to clear filtering.\n
- Show more information under "About assignment".\n`,
    newsHeader: "News 2023-09-27:",
    no: "No",
    noEditing: "Can not change under \"edit-mode\"",
    notificationCategoriesInfo: "The notification only adds the matching categories",
    notifications: "Notifications",
    notifName: "Notification-Name",
    notifNameInfo: "The name for the notification, eg. embedded in west",
    notifPeriodicity: "How often do you want to get a notifications-email?",
    notifSameName: "You already have a notification with this name, please choose a new name.",
    notifUpd1: "Notification '",
    notifUpd2: "' uppdated",
    of: "of",
    onceADay: "Once a day (09:00)",
    onceAMonth: "Once a month (First day (mon-fri) of the month 09:00)",
    onceAWeek: "Once a week (Enery monday 09:00)",
    openSettings: "Open settings",
    region: "Region:",
    region2: "Region",
    removeFavorite: "Remove favorite",
    removeNotif: "Do you want to remove the notification?",
    removeSeen: "Mark as unread",
    results: "results",
    search: "search",
    searchAssignment: "Search assignment",
    searchWord: "Search term",
    searchWordAdded: "The search term is already added, please choose another.",
    searchWordAtLeast2: "The search term needs to be at least two characters!",
    searchWordInfo: "The notification only includes assignments that match one of the search terms, leave blank to include everything",
    searchWordPlaceholder: "Write a search term and press enter",
    selectRegions: "Select regions:",
    sendout: "Sendouts",
    share: "Share",
    shareFavoriteList: "Share favorites list",
    south: "South",
    start: "Start:",
    switchLanguage: "På Svenska",
    tryAgain: "Try again",
    twiceADay: "Twice a day (09:00 och 13:00)",
    west: "West",
    welcome: "Welcome to the Assignment portal!",
    updNotif: "Uppdate notification",
    yes: "yes",
    //inDevelopment: "(in development)",
    yourNotif: "Your notifications",
  }
}


export default txt