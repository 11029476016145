import React, {useCallback} from "react";
import {useDropzone} from 'react-dropzone'
import ReactModal from "react-modal";

import Form from "react-bootstrap/Form";
import Spinner from 'react-bootstrap/Spinner'

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { sv } from 'date-fns/locale'

import { endpoint, adalApiFetch } from "../../adalConfig";
import { FaRegTrashAlt } from 'react-icons/fa';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './style.css';

const customStyles = {
  content : {
    // top                   : '50%',
    // left                  : '50%',
    // right                 : 'auto',
    // bottom                : 'auto',
    // marginRight           : '-50%',
    // transform             : 'translate(-50%, -50%)',
    top: '18px',
    left: '18px',
    right: '18px',
    bottom: '18px',
    color: "#222222",
    zIndex: 1000000000,
  },
  overlay: {
    zIndex: 1000000000
  },
};


registerLocale("sv", sv);
let now = new Date();
const cleanState = {
  'title'       : '',
  'origin'      : '',
  'description' : '',
  'text'        : '',
  region: 0,
  start: now.toISOString(),
  end: now.toISOString(),
  arrived: now.toISOString(),
  respond: now.toISOString(),
  'isLoading'   : false,
  'isUploading' : false,
  'isError'     : false,
  msg: '',
  attachmentName: null,
  attachment: null,
  attachmentHash: null,
}

//för nu, skriv location så att region hamnar rätt.
const regionMap = [
  'non-specific',
  'göteborg',
  'malmö',
  'stockholm'
]

const url = endpoint;

function FileDropzone({onUpdateAttachment, onUpdateProgress, isUploading}) {

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onloadstart = () => onUpdateProgress(true);
    reader.onloadend = () => onUpdateProgress(false);
    reader.onload = () => {
      const binaryStr = reader.result;
      //update and convert string to base64
      onUpdateAttachment(btoa(binaryStr),acceptedFiles[0]);
    }
    reader.readAsBinaryString(acceptedFiles[0]);
  }, [onUpdateAttachment, onUpdateProgress])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} className='fileDrop' style={{padding: '1.5em', height: '6em', borderRadius: '4px', textAlign: 'center'}}>
      <input {...getInputProps()} />
      {isUploading ? <div>
        <Spinner animation="border" role="status">
          <span className="sr-only">Laddar upp bilaga...</span>
        </Spinner>
        <p>Laddar upp bilaga...</p></div> :
      <h6>Dra in fil hit ifrån utforskaren, eller klicka här för att välja fil</h6>}
    </div>
  )
}

class EditRekaModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = cleanState;

    this.onChangeDateStart = this.onChangeDateStart.bind(this);
    this.onChangeDateEnd = this.onChangeDateEnd.bind(this);
    this.onChangeDateArrived = this.onChangeDateArrived.bind(this);
    this.onChangeDateDeadline = this.onChangeDateDeadline.bind(this);
    this.onUpdateAttachment = this.onUpdateAttachment.bind(this);
  }

  onUpdateAttachment(att, info) {
    this.setState({
      attachmentName: info.name,
      attachment: att,
      attachmentHash: "null",
    })
  }

  save(key) {
    this.setState({ isLoading: true });
    const readHash = this.state.attachmentHash;
    const aHash = (readHash && (readHash !== 'null')) ? readHash : null;
    console.log(readHash, aHash);

    adalApiFetch(fetch, `${url}/putreka/${key}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        title: this.state.title,
        origin: this.state.origin,
        description: this.state.description,
        text: this.state.text,
        respond: this.state.respond,
        submitted: now,
        arrived: this.state.arrived,
        region: this.state.region,
        attachment: this.state.attachment,
        attachmentName: this.state.attachmentName,
        attachmentHash: aHash,
        attr: JSON.stringify({
          end: this.state.end,
          rate: "0",
          level: "0",
          start: this.state.start,
          business_unit: "0000",
          classification: "none",
          location: regionMap[this.state.region]
        })
      })
    })
      // .then(response => response.json())
      .then((res) =>
        {this.setState({
          isLoading: false,
          callbackMsg: this.props.id + " uppdaterad"
        });

        console.log(res);
        this.props.history.replace(`/id/${this.props.id}`);
        this.props.history.go(`/`);

        }
      )
      .catch(err => console.log(err));
  }

  saveNew() {
    this.setState({ 'isLoading' : true});
    adalApiFetch(fetch, `${url}/postreka/`, {
      'method' : 'POST',
      'headers' : {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: this.state.title,
        origin: this.state.origin,
        description: this.state.description,
        text: this.state.text,
        respond: this.state.respond,
        submitted: now,
        arrived: this.state.arrived,
        attachment: this.state.attachment,
        attachmentName: this.state.attachmentName,
        attachmentHash: null,
        attr: JSON.stringify({
          end: this.state.end,
          rate: "0",
          level: "0",
          start: this.state.start,
          business_unit: "0000",
          classification: "none",
          location: regionMap[this.state.region],
        })
      })
    })
      .then(res => {
        console.log(res);
        this.setState({ ...cleanState, 'callbackMsg' : res});
        this.props.onRequestClose();
        this.props.history.replace(`/`);
        this.props.history.go(`/`);
        })
      .catch((err) => console.log(err));
  }

  loadData(key) {
    if (this.props.createNew) {
      this.setState(cleanState);
      return;
    }
    this.setState({ isLoading: true });
    adalApiFetch(fetch, `${url}/getreka/${key}`)
      .then(response => response.json())
      .then(res => {
        const attr = res.attr;
        this.setState({ msg: "", isLoading: false, ...res, ...attr });
      })
      .catch(err => this.setState({ callbackMsg: err }));
  }

  confirmDelete = (key) => {
    confirmAlert({
      title: 'Ta bort uppdrag',
      message: 'Är du säker du vill ta bort uppdraget?',
      buttons: [
        {
          label: 'Ja, ta bort uppdraget',
          onClick: () => this.deleteReka(key)
        },
        {
          label: 'Nej',
          onClick: () => {}
        }
      ]
    });
  };

  deleteReka(key) {
    this.setState({ isLoading: true });

    adalApiFetch(fetch, `${url}/deleteReka/${key}`, {
      method: "DELETE"
    })
      .then(res => {
        this.setState({
          isLoading: false,
          callbackMsg: this.props.id + " borttagen"
        });

        this.props.history.replace(`/`);
        this.props.history.go(`/`);
      })
      .catch(err => this.setState({ callbackMsg: err }));
  }

  //Kontrollerade komponenter, handlers
  onChangeTitle(e) {
    this.setState({ title: e.target.value });
  }

  onChangeRegion(e) {
    this.setState({ region: e.target.value });
  }

  onChangeOrigin(e) {
    this.setState({ origin: e.target.value });
  }

  onChangeLocation(e){}
  
  onChangeDescription(e) {
    this.setState({ description: e.target.value });
  }
  onChangeText(e) {
    this.setState({ text: e.target.value });
  }

  onChangeDateStart(date) {
    this.setState({
      start: date.toISOString()
    });
  }
  onChangeDateEnd(date) {
    this.setState({
      end: date.toISOString()
    });
  }
  onChangeDateArrived(date) {
    this.setState({
      arrived: date.toISOString()
    });
  }
  onChangeDateDeadline(date) {
    this.setState({
      respond: date.toISOString()
    });
  }

  checkValid() {
    //simple check for truesy now
    return (this.state.title && this.state.description && this.state.text && this.state.origin && !this.state.isUploading)
  }

  onCreate(e) { 
    if (this.checkValid()) {
      this.save(this.props.id);
    }else {
      this.setState({
        'msg': 'Fel: Saknar minsta nödvändiga uppgifter eller uppladdning av bilaga ej klar',
      })
    }
    e.preventDefault();
    
  }

  onCreateNew(e) {
    if (this.checkValid()) {
      this.saveNew(this.props.id);
    }else {
      this.setState({
        'msg': 'Fel: Saknar minsta nödvändiga uppgifter eller uppladdning av bilaga ej klar',
      })
    }
    e.preventDefault();
  }

  onDelete(e) {
    e.preventDefault();
    // this.deleteReka(this.props.id);
    this.confirmDelete(this.props.id);
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        style={customStyles}
        closeTimeoutMS={50}
        onRequestClose={this.props.onRequestClose}
        contentLabel="Edit Reka"
        shouldCloseOnOverlayClick={true}
        role="dialog"
        parentSelector={() => document.body}
        onAfterOpen={() => this.loadData(this.props.id)}
      >
        <Form className="editForm">
          {this.props.id ? (
            <h3>Redigerar uppdrag</h3>
          ) : (
            <h3>Lägg till nytt uppdrag</h3>
          )}
          <Form.Group controlId="editRekaForm">
          <br/>
            <h5>Titel</h5>
            <Form.Control
              type="text"
              placeholder="Uppdragets titel"
              className="mb-4"
              onChange={e => this.onChangeTitle(e)}
              value={this.state.title}
              style={{minWidth: '35em'}}
            />

            <h5>Region</h5>
            {/* <Form.Control
              type="text"
              placeholder="0 - alla, 1 - Väst, 2 - Syd, 3 - Öst"
              className="mb-4"
              onChange={e => this.onChangeRegion(e)}
              value={this.state.region}
            /> */}
            <Form.Control 
              onChange={e => this.onChangeRegion(e)}
              value={this.state.region}
              as="select"
              style={{minWidth: '35em', marginBottom: "1.4em"}}
              >
              {/* <option value="0">Alla</option> */}
              <option value="1">Väst</option>
              <option value="2">Syd</option>
              <option value="3">Öst</option>
            </Form.Control>

            <h5>Källa/Företag</h5>
            <Form.Control
              type="text"
              placeholder="Uppdragets ursprung, ex företag eller ework"
              className="mb-4"
              onChange={e => this.onChangeOrigin(e)}
              value={this.state.origin}
            />
            {/*  <h5>Lokalisering</h5>
            <Form.Control
              type="text"
              placeholder="Uppdragets plats"
              className="mb-4"
              onChange={e => this.onChangeLocation(e)}
              value={this.state.origin}
            /> */}
            <h5>Sammanfattning</h5>
            <Form.Control
              as="textarea"
              placeholder="Ge uppdraget en kortare beskrivning"
              rows="2"
              className="mb-4"
              onChange={e => this.onChangeDescription(e)}
              value={this.state.description}
            />
            <h5>Fullständig beskrivning</h5>
            <Form.Control
              as="textarea"
              placeholder="En längre mer utförlig beskrivning av uppdraget"
              rows="4"
              onChange={e => this.onChangeText(e)}
              value={this.state.text}
            />
          </Form.Group>

          <br/>
          <h5>Bifogad fil</h5>
          {(this.state.attachmentName) ? (
            <h5>{this.state.attachmentName}
              <button style={{marginLeft: '4px'}} className='btn' onClick={(e) => {e.preventDefault(); this.setState({attachment: null, attachmentName: null, attachmentHash: null})}}> X </button>
            </h5>
          ) : (
            <FileDropzone isUploading={this.state.isUploading} onUpdateProgress={(progress) => this.setState({isUploading: progress})} onUpdateAttachment={(att, info) => this.onUpdateAttachment(att,info)}/>
          )}
          <br/>
          <Form.Group>
            <div style={{ display: "block" }}>
              <Form.Label style={{ width: "8em" }}>Startdatum: </Form.Label>
              <DatePicker
                selected={this.state.start && parseISO(this.state.start)}
                onChange={e => this.onChangeDateStart(e)}
                dateFormat="dd MMMM, yyyy"
                locale='sv'
              />
            </div>
            <div style={{ display: "block" }}>
              <Form.Label style={{ width: "8em" }}>Slutdatum: </Form.Label>
              <DatePicker
                selected={this.state.end && parseISO(this.state.end)}
                onChange={e => this.onChangeDateEnd(e)}
                dateFormat="dd MMMM, yyyy"
                locale='sv'
              />
            </div>
            <div style={{ display: "block" }}>
              <Form.Label style={{ width: "8em" }}>
                Inkommen vid:{" "}
              </Form.Label>
              <DatePicker
                selected={this.state.arrived && parseISO(this.state.arrived)}
                onChange={e => this.onChangeDateArrived(e)}
                dateFormat="dd MMMM, yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                locale='sv'
              />
            </div>
            <div style={{ display: "block" }}>
              <Form.Label style={{ width: "8em" }}>
                Sista svarsdag:{" "}
              </Form.Label>
              <DatePicker
                selected={this.state.respond && parseISO(this.state.respond)}
                onChange={e => this.onChangeDateDeadline(e)}
                dateFormat="dd MMMM, yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                locale='sv'
              />
            </div>
          </Form.Group>


        </Form>
        {this.props.id ? (
            <section className="edit_toolbar">
              <button
                className="btn"
                onClick={e => this.onCreate(e)}
              >
                Spara ändringar
              </button>
              <button style={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight:'3em'}} className="btn cancel" onClick={e => this.onDelete(e)}>
                <FaRegTrashAlt size='10'/>
                <h6>Radera</h6>
              </button>

              <button className="btn cancel" onClick={(e) => {this.props.onRequestClose(); e.preventDefault()}}>
                <h6>Avbryt</h6>
              </button>

            </section>
          ) : this.state.isLoading ? (
            <section className="edit_toolbar">
              <h3>Sparar uppdrag...</h3>
            </section>
          ) : (
            <section className="edit_toolbar">
              <button
                className="btn"
                onClick={e => this.onCreateNew(e)}
                style={{ marginRight: "20px" }}
              >
                Lägg till uppdrag
              </button>

              <button className="btn cancel" onClick={(e) => {this.props.onRequestClose(); e.preventDefault()}}>
                <h6>Avbryt</h6>
              </button>
            </section>
          )}
          {this.state.msg && <h4 style={{color: 'red'}}>{this.state.msg}</h4>}
      </ReactModal>
    );
  }
}

export default EditRekaModal;
