import React, { useState } from "react";
import { Button } from "react-bootstrap";
import txt from "./../../assets/languages";

import "./style.css";

function Subscription({
  item,
  onRemove,
  onEdit,
  startActive,
  onAddExpanded,
  onRemoveExpanded,
  lang
}) {
  const [active] = useState(startActive);
  return (
    <div className="notification_holder" onClick={() => {
      !active? onAddExpanded(item.name): onRemoveExpanded(item.name)
    }} title={active? "Click to minimize":"Click to expand"}>
      <div className="notificationTop">
        <h2 className="h22">{item.name}</h2>
        {active ? (<ul className="list">
            <li>
              {txt[lang].region2} : {!Array.isArray(item.region) ? item.region: item.region.join(", ")}
            </li>
            <li>
              {txt[lang].categories2} : {item.filter.length<1? "all":!Array.isArray(item.filter) ? item.filter: item.filter.join(", ")}
            </li>
            <li>
              {txt[lang].sendout} : {item.update_interval? item.update_interval.replace(/_/g, " "):""}
            </li>
            <li>
              {txt[lang].searchWord} : {!Array.isArray(item.keywords) ? item.keywords: item.keywords.join(", ")}
            </li>
            <li>
              {txt[lang].latestSendout} : {item.last_updated? item.last_updated:""}
            </li> 
            <li>
              {txt[lang].matchesCount} : {item.notified_keys? item.notified_keys.length:""}
            </li> 
            
          </ul>):""}
      </div>
      <div className="button-container">
        <Button className="SubscriptButton" onClick={(e) => {onRemove(item); e.stopPropagation()} }  title="">{txt[lang].deleteNotif}</Button>
        <Button className="SubscriptButton" onClick={(e) => {onEdit(item); e.stopPropagation()}} title="">{txt[lang].edit}</Button>
      </div>
    </div>
  );
}

export default Subscription;
