import React, { Component } from "react";
import txt from "./../../assets/languages";

class CheckBox extends Component {
  checkBoxDiv = React.createRef();

  onSetCheckboxes = (checkBoxList) => {
    let children = this.checkBoxDiv.current.children;
    const checkBoxes = Array.from(children).filter(
      (child) => child.type === "checkbox"
    );
    if (checkBoxList !== null) {
      checkBoxes.forEach((box) => {
        box.checked = false;
        if (checkBoxList.includes(box.value) || checkBoxList.includes(box.value.toLowerCase())) {
          box.checked = true;
        }
        if (checkBoxList.length === 3 && box.value === "All") {
          box.checked = true;
        }
      });
    }
  };

  onChange = (event) => {
    var ReactDOM = require("react-dom");
    let children = ReactDOM.findDOMNode(event.target).parentNode.children;
    const checkBoxes = Array.from(children).filter(
      (child) => child.type === "checkbox"
    );
    ///If event value is all either check all, or uncheck all.
    if (event.target.value === "All") {
      ///Select all.
      if (event.target.checked) {
        checkBoxes.forEach((box) => {
          box.checked = true;
        });
        ///Deselect all.
      } else {
        checkBoxes.forEach((box) => {
          box.checked = false;
        });
      }
      ///If not all.
    } else {
      ///Loop through all checks, if any of them is not selected, then unselect all checkbox.
      let allChecked = true;
      checkBoxes.forEach((box) => {
        if (box.value !== "All" && !box.checked) {
          allChecked = false;
        }
      });
      ///If All is checked and something is unchecked, uncheck all checkbox.
      checkBoxes[0].checked = allChecked;
    }
    ///ResultList is checkboxes that is checked and not "All".
    let resultList = checkBoxes.filter(
      (box) => box.checked === true && box.value !== "All"
    );
    ///Store the values, like South, or West etc.
    resultList = resultList.map((box) => box.value);
    ///Update the state in [SubscriptionPage/index.js].
    this.props.onSetRegion(resultList);
  };

  render() {
    return (
      <div ref={this.checkBoxDiv}>
        <p>{txt[this.props.lang].selectRegions}</p>
        <input
          className="checkbox"
          type="checkbox"
          name="regions"
          value="All"
          onChange={this.onChange}
        />
        <label> {txt[this.props.lang].all}</label>
        <br />
        <input
          className="checkbox"
          type="checkbox"
          name="regions"
          value="West"
          onChange={this.onChange}
          style={{
            color: "#f3642c",
          }}
        />
        <label> {txt[this.props.lang].west}</label>
        <br />
        <input
          className="checkbox"
          type="checkbox"
          name="regions"
          value="South"
          onChange={this.onChange}
        />
        <label> {txt[this.props.lang].south}</label>
        <br />
        <input
          className="checkbox"
          type="checkbox"
          name="regions"
          value="East"
          onChange={this.onChange}
        />
        <label> {txt[this.props.lang].east}</label>
        <br />
      </div>
    );
  }
}
export default CheckBox;
