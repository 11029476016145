import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';
 
// Endpoint URL
let website_url = "https://uppdragsportalen.essiq.se/api"
export const endpoint = website_url;

// App Registration ID
const appId = "7483a4e5-b99d-4ab0-9476-5697491e7ce0";

export const adalConfig = {
  tenant: '85d74185-d028-46fe-821e-e496403d2411',
  clientId: appId,
  endpoints: {
    me: 'https://graph.microsoft.com/v1.0/me/',
    api: '5b09f405-963e-4a48-90f8-7464468c5bd5'
  },
  cacheLocation: 'localStorage',
  secret: 'rCW8]Rm3YWOx0]i_F2+T:qDEADAP/1nR',
  consentScopes: ["user.read"]
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = (url) => adalGetToken(authContext,url);
 
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
 
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);