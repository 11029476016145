import React from "react";
import "./style.css";
import { Button } from "react-bootstrap";

class Popup extends React.Component {
    render() {
      return (
        <div className="popup" onClick={this.props.closePopupCancel}>
          <div className='popup_inner'>
            <div>
              <h3>{this.props.header}</h3>
              <p>{this.props.text}</p>
            </div>
            <div className="flex-container">
              <Button 
                onClick={this.props.closePopupOK}
                type="button"
                className="custom-btn-p">
                {this.props.btn1text}
              </Button>
              {this.props.btn2text.length>0 && 
                <Button 
                onClick={this.props.closePopupCancel}
                type="button"
                className="custom-btn-p">
                {this.props.btn2text}
              </Button>}
            </div>
          </div>
        </div>
      );
    }
  }/*
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        showPopup: false
      };
    }
    togglePopup() {
      this.setState({
        showPopup: !this.state.showPopup
      });
    }
    render() {
      return (
        <div className='app'>
          <h1>hihi</h1>
          <button onClick={this.togglePopup.bind(this)}>show popup</button>
          <button onClick={() => {alert('woooooooot?');}}>try me when popup is open</button>
          {this.state.showPopup ? 
            <Popup
              text='Close Me'
              closePopup={this.togglePopup.bind(this)}
            />
            : null
          }
        </div>
      );
    }
  };*/
  export default Popup;