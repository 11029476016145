import React from "react";
import { Link } from "react-router-dom";
import { authContext } from "./../../adalConfig";
import OutsideDetector from "./../OutsideDetector/OutsideDetector";
import { FaShareSquare } from 'react-icons/fa';
import { IoIosGlobe } from 'react-icons/io';

import essiq from "../../assets/essiq.png";
import icon_user from "../../assets/Icons/ICON_USER.svg";
import icon_options from "./../../assets/Icons/ICON_OPTIONS.svg";
import icon_notification from "./../../assets/Icons/ICON_NOTIFICATION.svg";
import icon_fold from "./../../assets/Icons/ICON_FOLD.svg";
import icon_add from "./../../assets/Icons/ICON_ADD.svg";
import icon_settings from "./../../assets/Icons/ICON_SETTINGS_SOLID.svg";
import icon_settings_inv from "./../../assets/Icons/ICON_SETTINGS_SOLID_INV.svg";
import icon_search from "./../../assets/Icons/ICON_SEARCH_SOLID.svg";
import icon_region_marker from "./../../assets/Icons/ICON_REGION_MARKER.svg";
import txt from "./../../assets/languages";

import "./style.css";


const Header = ({
  onInitialSearch,
  isLoading,
  isAdmin,
  input,
  region,
  onSetRegion,
  onClearFilters,
  onAuth,
  userEmail,
  onReset,
  onCollapse,
  onShareFavourites,
  onSwitchLanguage,
  collapsed,
  onHamburgerCollapse,
  hamburgerDown,
  history,
  onEdit,
  onSubscribe,
  lang,
}) => (
  <header>
    <nav className={!hamburgerDown ? "" : "collapsed"}>
      <Link to="/">
        <div className="brand">
          <img id="brand_logo" src={essiq} alt="essiq-logo" />
          <span>{txt[lang].assignmentportal}</span>
        </div>
      </Link>

      <div className={!hamburgerDown ? "rightBar" : "rightBar collapsed"}>
        <form
          onSubmit={(e) => {
            onInitialSearch(e);
            onHamburgerCollapse();
            history.push("/");
          }}
          className="searchField"
        >
          <div
            onClick={(e) => {
              onInitialSearch(e);
              onHamburgerCollapse();
            }}
            className="center"
          >
            <Link to="/">
              <img
                src={icon_search}
                alt={txt[lang].search}
                width="26px"
                style={{ marginRight: "6px" }}
              />
            </Link>
          </div>

          <input
            type="text"
            ref={input}
            placeholder={txt[lang].searchAssignment}
            aria-label={txt[lang].searchAssignment}
            onFocus={(e) => e.target.select()}
          />

          <div onClick={onReset} className="abort center">
            <img src={icon_fold} alt="rensa" width="28px" />
          </div>
        </form>

        <div
          className="region"
          onClick={(e) => {
            onSetRegion(e);
            history.push("/");
          }}
        >
          <h5 style={{ width: "2.5em" }}>
            {region === 1
              ? txt[lang].west
              : region === 2
              ? txt[lang].south
              : region === 3
              ? txt[lang].east
              : txt[lang].all}
          </h5>
          <img
            src={icon_region_marker}
            alt="region-select"
            className="region-select"
            width="32px"
          />
        </div>
        <div
          className="region"
          onClick={(e) => {
            onClearFilters(e);
            history.push("/");
          }}
        >
          <h5 style={{ width: "6em" }}>{txt[lang].clearFilters}</h5>
          <div className="abort center">
            <img src={icon_fold} alt="rensa" width="28px" />
          </div>
        </div>

        <OutsideDetector
          onHandleOutside={() => {
            if (!collapsed) {
              onCollapse();
            }
          }}
        >
          <span
            onClick={(e) => onCollapse(e)}
            className={
              collapsed
                ? "settingsToggle collapsed center"
                : "settingsToggle center"
            }
          >
            <img
              src={collapsed ? icon_settings : icon_settings_inv}
              alt={txt[lang].search}
              width="28px"
            />
          </span>

          <div
            className={
              collapsed ? "settingsDropdown collapsed" : "settingsDropdown"
            }
          >
            <ul className="settingsList">
              <li onClick={() => onSwitchLanguage()}>
              <span style={{width: "32px", textAlign: "center"}}><IoIosGlobe size="19" /></span>
                {txt[lang].switchLanguage}
              </li>
              {/* <Link to="/new/"> */}
              {isAdmin && (
                <li
                  onClick={() => {
                    // onCollapse();
                    // onHamburgerCollapse();
                    onEdit();
                  }}
                >
                  <img src={icon_add} alt={txt[lang].addAssignment} width="32px" />
                  {txt[lang].addAssignment}
                </li>
              )}
              {/* </Link> */}

              {
                // (isAdmin || devMailList.indexOf(userEmail)>-1) &&
                <li
                  onClick={() => {
                    onCollapse();
                    onSubscribe();
                  }}
                  style={{ opacity: "75%", color: "red" }}
                >
                  <img
                    src={icon_notification}
                    alt={txt[lang].notifications}
                    width="32px"
                    style={{ opacity: "75%" }}
                  />
                  {txt[lang].notifications}
                </li>
              }
              <li onClick={() => onShareFavourites()}>
                <span style={{width: "32px", textAlign: "center"}}><FaShareSquare size="19" /></span>
                {txt[lang].shareFavoriteList}
              </li>
              <li onClick={() => authContext.logOut()}>
                <img src={icon_user} alt={txt[lang].logOut} width="32px" />
                {txt[lang].logOut}
              </li>
            </ul>
          </div>
        </OutsideDetector>
      </div>
    </nav>
    <div className="hamburger" onClick={(e) => onHamburgerCollapse(e)}>
      {hamburgerDown ? (
        <img src={icon_options} alt={txt[lang].openSettings} width="40px" />
      ) : (
        <img src={icon_fold} alt={txt[lang].openSettings} width="44px" />
      )}
    </div>
  </header>
);

export default Header;
