import Form from "react-bootstrap/Form";
import "./style.css";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Subscription from "../Subscription";
import CategoryPane from "../CategoryPane";
import Popup from "../Popup";
import CheckBox from "./checkBox";
import txt from "./../../assets/languages";

function SubscriptionPage({
  onClose,
  userEmail,
  onSubscribeBtnClicked,
  onRemoveBtnClicked,
  getNotifications,
  notificationResult,
  lang,
}) {
  const [notificationName, setNotificationName] = useState("");
  const [textPop, setTextpop] = useState("hello");
  const [popupMsg, setPopupMsg] = useState("");
  const [popupMode, setPopupMode] = useState("");
  const [popupData, setPopupData] = useState();
  const [popupBtnTexts, setPopupBtnTexts] = useState(["OK",""]);
  const [region, setRegion] = useState(["west", "east", "south"]);
  const [searchWords, setSearchWords] = useState([txt[lang].searchWord]);
  const [tempSearchWord, setTempSearchWord] = useState("");
  const [updateInterval, setUpdateInterval] = useState("once_a_day");
  const [categoriesString, setCategoriesString] = useState([]);
  const [isEditing, setisEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [expandedNotifications, setexpandedNotifications] = useState([]);
  const [categories, setCategories] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  //8 categories

  const checkBoxRef = React.createRef();
  const nameRef = React.createRef();

  useEffect(() => {
    loadNotificationsFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNotificationName = (e) => {
    setNotificationName(e.target.value);
  };
  const onSetRegion = (newList) => {
    setRegion(newList);
  };

  const loadNotificationsFromDB = () => {
    getNotifications(userEmail);
    for(var i=0;i++;i<notificationResult.length){
      expandedNotifications(notificationResult[i].name)
    }

    //setNotifications(notificationResult)
  };

  //Used for editing
  const loadValuesFromObject = (e) => {
    //Set name
    let name = e.name;
    nameRef.current.value = name;
    setNotificationName(name);

    //Set checkboxes
    checkBoxRef.current.onSetCheckboxes(e.region);

    //Set filter
    const filter = Array.from(e.filter);
    let filterBoolarray = Array(categories.length).fill(false);
    filter.forEach((element) => {
      let index = txt[lang].categoryLabels.indexOf(element);
      filterBoolarray[index] = true;
    });
    setCategories(filterBoolarray);

    //Set searchwords
    if(e.keywords.length>0){
      setSearchWords(e.keywords);
    }else{
      setSearchWords([txt[lang].searchWord]);
    }
  };

  const addExpandedNotification = (name) => {
    if (!expandedNotifications.includes(name)) {
      setexpandedNotifications([...expandedNotifications, name]);
    }
  };

  const removeExpandedNotification = (name) => {
    let newlist = expandedNotifications.filter((item) => item !== name);
    setexpandedNotifications(newlist);
  };

  const onShowPopup = (msg, timer=false, mode = "",btnNames = ["OK",""]) => {
    setPopupMsg(msg);
    setPopupMode(mode)
    setPopupBtnTexts(btnNames);
    setShowPopup(!showPopup);
  }
  const closePopupOK = () => {
    if(popupMode === "removeNoti"){
      onRemoveBtnClicked(popupData[0], popupData[1]);
    }
    setShowPopup(!showPopup);

  }
  const closePopupCancel = (e) => {
    setShowPopup(!showPopup);

  }
  
  const onShowPopText = (msg) => {
    setTextpop(msg);
    document.getElementById("pop").className = "poptext1";
    setTimeout(function(){ if(document.getElementById("pop"))document.getElementById("pop").className = "poptext0"; },2500)
    //alert(document.getElementById("pop").className)
  }

  const onEdit = (e) => {
    addExpandedNotification(e.name);
    setisEditing(true);
    loadValuesFromObject(e);
  };

  // eslint-disable-next-line no-unused-vars
  const onUpdateInterval = (e) => {
    let value = e.target.value;

    // value = e.target[value].innerText;
    let result = "";
    if (value === "0") {
      result = "once_a_day";
    }
    if (value === "1") {
      result = "twice_a_day";
    }
    if (value === "2") {
      result = "weekly";
    }
    if (value === "3") {
      result = "monthly";
    }
    setUpdateInterval(result);
  };

  const onSetCategories = (e) => {
    let ID = e.target.getAttribute("value");
    let filtz = [...categories];
    filtz[ID] = !filtz[ID];
    setCategories(filtz);
    onSetCategoriesString(filtz);
  };

  var allCategoriesTrue = categories.every((categorie) => categorie === true);
  var selectAllText = allCategoriesTrue ? txt[lang].chooseNone : txt[lang].chooseAll;
  const selectAllCategories = (e) => {
    let filtz = [];
    if (allCategoriesTrue) {
      // see if all are true
      filtz = Array(categories.length).fill(false);
    } else {
      filtz = Array(categories.length).fill(true);
    }
    setCategories(filtz);
    onSetCategoriesString(filtz);
    setNotificationName("Notification1");
  };

  const onSetCategoriesString = (e) => {
    let cString = [];
    let cat = [...e];
    for (let i = 0; i < cat.length; i++) {
      if (cat[i] === true) {
        cString = [...cString, txt[lang].categoryLabels[i]];
      }
    }
    setCategoriesString(cString);
  };

  var isSearchWordsEmpty = searchWords[0] === txt[lang].searchWord;

  const onAddSearchword = (e) => {
    let word = e;
    if (isSearchWordsEmpty) {
      setSearchWords([word]);
    } else {
      setSearchWords([...searchWords, word]);
    }
    setTempSearchWord("");
  };

  const onAddSearchwordHack = (e) => {
    if (tempSearchWord.length > 1) {
      if (searchWords.includes(tempSearchWord)) {
        onShowPopup(txt[lang].searchWordAdded);
      } else {
        onAddSearchword(tempSearchWord);
        document.getElementById("searchWordInput").value = "";
      }
    } else {
      onShowPopup(txt[lang].searchWordAtLeast2);
    }
  };

  const onAddButtonClick = async (e) => {
    //togglePopup(true);
    let nameAlreadyPresent = false;

    if (!isEditing) {
      notificationResult.forEach((element) => {
        if (notificationName === element.name) {
          onShowPopup(txt[lang].notifSameName);
          nameAlreadyPresent = true;
        }
      });
    }
    if (!nameAlreadyPresent) {
      let localSearchwords = [];
      if (searchWords[0] !== txt[lang].searchWord) {
        localSearchwords = searchWords;
      }
      onSubscribeBtnClicked(
        userEmail,
        notificationName,
        region,
        categoriesString,
        localSearchwords,
        updateInterval
      );
      isEditing
        ? onShowPopText(txt[lang].notifUpd1 + notificationName + txt[lang].notifUpd2,true)
        : onShowPopText(txt[lang].newNotif1 + notificationName + txt[lang].newNotif2,true);
      setisEditing(false);
      addExpandedNotification(notificationName)
      clearForm(); 
      
    }
  };

  const clearForm = (e) => {
    //document.getElementById("notisFormular").reset();
    nameRef.current.value = "";
    setRegion(["west", "east", "south"]);
    checkBoxRef.current.onSetCheckboxes(["", "", "",""]);
    setNotificationName("");
    setSearchWords([txt[lang].searchWord]);
    let filtz = Array(categories.length).fill(false);
    setCategories(filtz);
    onSetCategoriesString(filtz);
    //alert(checkBoxRef.current.Array)
  };

  const onDeleteButtonClicked = (e) => {
    // eslint-disable-next-line no-restricted-globals
    setPopupData([userEmail,e.name])
    onShowPopup(txt[lang].removeNotif, false, "removeNoti", [txt[lang].yes, txt[lang].no]);
    /*if (confirm("Vill du ta bort notifikationen?")) {
      onRemoveBtnClicked(userEmail, e.name);
    }*/
  };

  const onRemoveSearchword = (e) => {
    setSearchWords(searchWords.filter((searchWord) => searchWord !== e));
    if (searchWords.length === 1) {
      setSearchWords([txt[lang].searchWord]);
    }
  };

  return (
    <div>
      <div className="subscriptionPageBackground" onClick={onClose}></div>
      <div className="SubscriptionPage">
        {showPopup &&
          <Popup 
            header={popupMsg}
            text={""}
            btn1text={popupBtnTexts[0]}
            btn2text={popupBtnTexts[1]}
            closePopupOK={closePopupOK.bind(this)}
            closePopupCancel={closePopupCancel.bind(this)}
          />
        }
        <h1 style={{ marginTop:"0.5em" ,marginLeft:"0.7em"}}>{txt[lang].loggedInAs} {userEmail}</h1>
        <div className="sideBar holderBg">
          <h2 style={{ color: "white" ,marginLeft:"0.9em"}}>{txt[lang].yourNotif}</h2>
          {notificationResult != null
            ? Object.values(notificationResult).map((item) => (
                <Subscription
                  key={Math.random().toString(36).substr(2, 9)}
                  item={item}
                  onRemove={onDeleteButtonClicked}
                  onEdit={onEdit}
                  startActive={expandedNotifications.includes(item.name)}
                  onAddExpanded={addExpandedNotification}
                  onRemoveExpanded={removeExpandedNotification}
                  lang={lang}
                />
              ))
            : "Nothing to show"}
        </div>
        <div className="spacer"></div>
        <div
          className="formHolder holderBg"
          id="centerFormHolder"
          style={isEditing ? { backgroundColor: "rgba(255,0,0,0.1)" } : {}}
        >
          <Form className="editForm">
            <Form.Group>
              <br />
              <div className="flexdiv">
                <h5>{txt[lang].notifName}</h5>
                {isEditing ? <p>{txt[lang].noEditing}</p> : ""}
              </div>
              <Form.Control
                ref={nameRef}
                disabled={isEditing}
                type="text"
                placeholder={txt[lang].notifNameInfo}
                className="mb-4"
                style={
                  isEditing
                    ? {
                        minWidth: "35em",
                        color: "white",
                        backgroundColor: "rgba(255,0,0,0.3)",
                        marginTop: "-7px",
                      }
                    : { minWidth: "35em"
                      }
                }
                onChange={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                  onAddNotificationName(event);
                }}
              />

              <CheckBox ref={checkBoxRef} onSetRegion={onSetRegion} lang={lang} />

              <h5>{txt[lang].categories}</h5>
              <p>{txt[lang].notificationCategoriesInfo}</p>
              <CategoryPane
                boxes={categories}
                onCheckCategory={(e) => onSetCategories(e)}
                isSubPage={true}
                lang={lang}
              />
              <div className="text-right">
                <Button
                  id="selectAll"
                  type="button"
                  onClick={() => selectAllCategories()}
                  className={
                    allCategoriesTrue ? "custom-btn-none" : "custom-btn-all"
                  }
                >
                  {selectAllText}
                </Button>
              </div>

              <h5>{txt[lang].notifPeriodicity}</h5>
              <Form.Control
                onChange={(e) => onUpdateInterval(e)}
                defaultValue={0}
                as="select"
                style={{ minWidth: "35em", marginBottom: "1.4em" }}
              >
                <option value="0">{txt[lang].onceADay}</option>
                <option value="1">{txt[lang].twiceADay}</option>
                <option value="2">{txt[lang].onceAWeek}</option>
                <option value="3">{txt[lang].onceAMonth}</option>
              </Form.Control>

              <h5>{txt[lang].searchWord}</h5>
              <p>
                {txt[lang].searchWordInfo}
              </p>
              <div className="searchWord-div">
                <Form.Control
                  type="text"
                  placeholder={txt[lang].searchWordPlaceholder}
                  rows="1"
                  className="mb-4"
                  id="searchWordInput"
                  onChange={(event) => {
                    setTempSearchWord(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      onAddSearchwordHack();
                    }
                  }}
                />
                <Button
                  className="custom-btn-addSW"
                  onClick={onAddSearchwordHack}
                >
                  {txt[lang].addNotif}
                </Button>
              </div>

              {searchWords.map((element) => (
                <div
                  id="container"
                  key={element}
                  className={isSearchWordsEmpty ? "containerTransparent" : ""}
                >
                  <div className="containerChild">{element}</div>
                  <button
                    className="clearButton"
                    id={
                      isSearchWordsEmpty
                        ? "clearButtonIDInactive"
                        : "clearButtonID"
                    }
                    type="button"
                    disabled={isSearchWordsEmpty}
                    onClick={() => onRemoveSearchword(element)}
                  >
                    X
                  </button>
                </div>
              ))}

              <div className="bottomButtonsDiv">
                <Button
                  className="custom-btn"
                  onClick={
                    notificationName !== ""
                      ? onAddButtonClick
                      : () => {
                        onShowPopup(txt[lang].fillNotifName);
                        }
                  }
                >
                  {isEditing ? txt[lang].updNotif : txt[lang].addNotification}
                </Button>

                {/* <p
                width={500}
                style={{ alignContent: "space-between", flexBasis: "45%" }}
               ></p> */}

              

                <Button
                  id="cancel button bottom"
                  className="custom-btn"
                  type="button"
                  onClick={
                    isEditing
                      ? () => {
                          setisEditing(false);
                          clearForm();
                        }
                      : onClose
                  }
                  disabled={!isEditing}
                  hidden={!isEditing}
                >
                  {isEditing ? txt[lang].cancelEdit : txt[lang].closeWindow}
                </Button>
                <div className="poptext0 center" id="pop">
                  <p>
                  {textPop}
                  </p>
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <Button className="closeButton" onClick={onClose}>
          X
        </Button>

        {/* <div className="infoDiv">
          FROM STATE
          <br />
          <br />
          notificationName: {notificationName}
          <br />
          region: {region.toString()}
          <br />
          kategorier: {categories.toString()}
          <br />
          categoriesString:{" "}
          {categoriesString.map((categoriesString) => categoriesString + ", ")}
          <br />
          updateInterval: {updateInterval}
          <br />
          searchWords: {searchWords.map((word) => word + ", ")}
        </div> */}
      </div>
      
    </div>
  );
}

export default SubscriptionPage;
