import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { endpoint, adalApiFetch } from "../../adalConfig";
import txt from "./../../assets/languages";

import {
  FaEdit,
  FaShareSquare,
  FaDownload,
  //FaExternalLinkAlt,
} from "react-icons/fa";
import { IoIosStarOutline, IoIosStar, IoIosBookmark } from "react-icons/io";

// import $scriptjs from 'scriptjs';


import "./style.css";

const url = endpoint;
//rkPrefix = "https://www.eworkgroup.com/eworkid/";

const formatDate = (dateStr, lang) => {
  const d = new Date(dateStr.substring(0, 10).toString());
  return d.getFullYear() > 2000
    ? d.getDate() + " " + txt[lang].months[d.getMonth()] + ", " + d.getFullYear()
    : "Ej angivet";
};
const formatRegion = (reg, lang) => {
  switch (reg) {
    case 1:
      return txt[lang].west;
    case 2:
      return txt[lang].south;
    case 3:
      return txt[lang].east;
    default:
      return "";
  }
};

const formatCity = (attr) => {
  try {
    return attr.city
  } catch (error) {
    console.log(error)
    return ""
  }
};

const formatCategories = (category) => {
  let categoryList = ["Ux", "It", "Embedded", "Testing", "Agile", "Visual", "Electronics", "Mechanics"]
  
  let output = ""
  for (let i in categoryList) {
    try {
      if (category[i] === true) output += categoryList[i] + ", "
    } catch {}
  }
  output = output.substring(0, output.length - 2);
  return output
};

/**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 * @param  {string} pdf
 * @return {void}
 */
function downloadPDF(pdf, filename) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = `${filename}`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

class DisplayPane extends React.Component {
  state = {
    isLoading: false,
  };

  handleAttachment(key) {
    adalApiFetch(fetch, `${url}/getAttachment/${key}`)
      .then((response) => response.json())
      .then((res) => {
        // console.log(res);
        if (res.attachmentName.length < 2) {
          console.log("ework");
        } else {
          downloadPDF(res.attachment, res.attachmentName);
        }
      })
      .catch((err) => console.log(err));
  }

  loadData(key) {
    this.setState({ isLoading: true });
    adalApiFetch(fetch, `${url}/getreka/${key}`)
      .then((response) => response.json())
      .then((res) => {
        // console.log(res);
        this.setState(res);
        this.setState({ isLoading: false });
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate() {
    // $scriptjs('https://c64.assets-yammer.com/assets/platform_embed.js', function(){
    //   window.yam.connect.embedFeed({
    //     container: "#embedded-feed",
    //     network: "essiq.se",
    //     feedType: "group",
    //     feedId: "1457260"
    //   });
    // });
  }

  componentDidMount() {
    this.props.id && this.loadData(this.props.id);
  }

  refetch(key) {
    // console.log(key, this.props.id);
    key && this.props.id !== 0
      ? this.loadData(key)
      : this.loadData(this.props.id);
  }

  handleFav(key, setTo) {
    // this.props.onHandleFav(id,setTo);
    !setTo
      ? adalApiFetch(fetch, `${url}/addfavorite/${key}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            this.props.onHandleFav();
            this.loadData(this.props.id);
          })
          .catch((err) => console.log(err))
      : adalApiFetch(fetch, `${url}/deletefavorite/${key}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            this.props.onHandleFav();
            this.loadData(this.props.id);
          })
          .catch((err) => console.log(err));
  }

  render() {
    return (
      <div
        className={
          this.props.match.isExact && this.props.match.path === "/"
            ? "displayPane root"
            : "displayPane"
        }
      >
        {this.state.isLoading ? (
          <Spinner
            animation="border"
            role="status"
            className="mt-5 d-flex justify-content-center spin"
          >
            <span className="sr-only">{txt[this.props.lang].loading}</span>
          </Spinner>
        ) : this.props.id ? (
          <Container className="displayPaneContainer" fluid={true}>
            <Row>
              <Col id="leftBar" xs={12} md={8}>
                <h3 className="accent">
                  {this.state.isFavorite ? (
                    <IoIosStar
                      className="star"
                      onClick={() => this.handleFav(this.props.id, true)}
                      size="50"
                      style={{ marginTop: -4 }}
                      title={txt[this.props.lang].favorite}
                    />
                  ) : (
                    <IoIosStarOutline
                      className="star"
                      onClick={() => this.handleFav(this.props.id, false)}
                      size="50"
                      style={{ marginTop: -4 }}
                      title={txt[this.props.lang].favClickToAdd}
                    />
                  )}
                  {this.state.id && this.state.id}
                </h3>
                <h2 className="headline">
                  {this.state.title && this.state.title}
                </h2>
                <h3>{this.state.origin && this.state.origin}</h3>
                {/*
                <pre>
                  {this.state.description &&
                    this.state.description !== "None" &&
                    this.state.description.substring(0, 100) + "..."}
                  </pre>*/}
                  <br/>
                  <h5 className="accent">{txt[this.props.lang].description}</h5>
                {this.state.origin && this.state.origin === "EWORK" ? (
                  <pre>{this.state.text}</pre>
                ) : (
                  <div>
                    <pre>{this.state.description}</pre>
                    <br/>
                    {/* <pre>{this.state.text}</pre> */}
                    {this.state.id && !this.state.id.includes("JR-") && (
                      <p className="accent">{txt[this.props.lang].descLooksStrange}</p>
                    ) }
                  </div>
                )}
              </Col>
              <Col id="dateBar" xs={6} md={4} className="pl-3">
                <h5 className="accent">{txt[this.props.lang].aboutAssignment}</h5>
                {this.state.region !== 0 && (
                  <h3>
                    <strong>{txt[this.props.lang].region}</strong> {formatRegion(this.state.region, this.props.lang)}
                  </h3>
                )}
                {formatCity(this.state.attr) !== "" && (
                  <h3>
                    <strong>{txt[this.props.lang].city}</strong> {formatCity(this.state.attr)}
                  </h3>
                )}
                <h3>
                  <strong>{txt[this.props.lang].added}</strong>{" "}
                  {this.state.submitted && formatDate(this.state.submitted, this.props.lang)}
                </h3>
                <h3>
                  <strong>{txt[this.props.lang].start}</strong>{" "}
                  {this.state.attr &&
                    this.state.attr.start &&
                    formatDate(this.state.attr.start, this.props.lang)}
                </h3>
                <h3>
                  <strong>{txt[this.props.lang].end}</strong>{" "}
                  {this.state.attr &&
                    this.state.attr.end &&
                    formatDate(this.state.attr.end, this.props.lang)}
                </h3>
                <h3>
                  <strong>{txt[this.props.lang].answerLatest}</strong>{" "}
                  {this.state.respond && formatDate(this.state.respond, this.props.lang)}
                </h3>
                <h3>
                  <strong>{txt[this.props.lang].categories}</strong> {formatCategories(this.state.category)}
                </h3>
                {this.state.hasOwnProperty("attr") && this.state.attr.hasOwnProperty("category_gpt") && (
                  <h3>
                    <strong>{txt[this.props.lang].categories_gpt}</strong> {formatCategories(this.state.attr.category_gpt)}
                  </h3>
                )}
                {this.state.hasOwnProperty("attr") && this.state.attr.hasOwnProperty("category_ai") && (
                  <h3>
                    <strong>{txt[this.props.lang].categories_ai}</strong> {formatCategories(this.state.attr.category_ai)}
                  </h3>
                )}
                {this.state.hasOwnProperty("attr") && this.state.attr.hasOwnProperty("parsed_by") && this.props.isAdmin && (
                  <h3>
                    <strong>Parsed by: </strong> {this.state.attr.parsed_by}
                  </h3>
                )}
                
                <hr />
                {/* TODO: fix the proper styles for these, doing inline now prototyping */}
                {this.props.isAdmin && (
                  <button className="btn sidebar" onClick={this.props.onEdit}>
                    <div className="icon_sidebar">
                      <FaEdit size="20" />
                    </div>
                    <h6>{txt[this.props.lang].change}</h6>
                  </button>
                )}

                {this.state.origin && this.state.id.includes("JR-") ? (
                  <button className="btn sidebar" disabled>
                    <div className="icon_sidebar">
                      <FaDownload size="19" />
                    </div>
                    <h6>{txt[this.props.lang].downloadOriginal}</h6>
                  </button>
                ) : (
                  <button
                    className="btn sidebar"
                    onClick={() => this.handleAttachment(this.props.id)}
                  >
                    <div className="icon_sidebar">
                      <FaDownload size="19" />
                    </div>
                    <h6>{txt[this.props.lang].downloadOriginal}</h6>
                  </button>
                )}
                {this.state.title && (
                  <a
                    href={`mailto:?subject=${
                      encodeURIComponent(txt[this.props.lang].emailSubjectOne)
                    }&body=${
                      encodeURIComponent(txt[this.props.lang].emailBodyOne)
                    }${
                      encodeURIComponent(this.state.title)
                    }%0AId%3A%20${
                      this.state.id
                    }%0Ahttps%3A%2F%2Fuppdragsportalen.essiq.se%2Fid%2F${
                      encodeURIComponent(this.state.key)
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn sidebar">
                      <div className="icon_sidebar">
                        <FaShareSquare size="19" />
                      </div>
                      <h6>{txt[this.props.lang].share}</h6>
                    </button>
                  </a>
                )}
                <button
                  className="btn sidebar"
                  onClick={() => this.props.onRemoveSeen(this.state.id)}
                >
                  <div className="icon_sidebar">
                    <IoIosBookmark size="19" />
                  </div>
                  <h6>{txt[this.props.lang].removeSeen}</h6>
                </button>
                {this.state.isFavorite ? (
                  <button
                    className="btn sidebar"
                    onClick={() => this.handleFav(this.props.id, true)}
                  >
                    <div className="icon_sidebar">
                      <IoIosStarOutline size="22" />
                    </div>
                    <h6>{txt[this.props.lang].removeFavorite}</h6>
                  </button>
                ) : (
                  <button
                    className="btn sidebar"
                    onClick={() => this.handleFav(this.props.id, false)}
                  >
                    <div className="icon_sidebar">
                      <IoIosStar size="22" />
                    </div>
                    <h6>{txt[this.props.lang].favorite}</h6>
                  </button>
                )}
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="displayPaneContainer" fluid={true}>
            <Welcome lang={this.props.lang} />
          </Container>
        )}
      </div>
    );
  }
}

const Welcome = ({lang}) => (
  <div className="welcome">
    <h2>{txt[lang].welcome}</h2>
    <br />
    <h4>{txt[lang].newsHeader}</h4>
    <p style={{whiteSpace: "pre-line"}}>{txt[lang].newsBody}</p>

    <div id="embedded-feed" style={{ height: "400px" }}></div>
  </div>
);

export default DisplayPane;

